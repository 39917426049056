import { ObservableMap } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { ITextProps, Text } from "@bps/fluent-ui";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { ConvertToRefData, RefData } from "@libs/api/ref-data/RefData.ts";
import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

import { DataFetcher } from "./data-fetcher/DataFetcher.tsx";

type RefTextProps = {
  map: Map<string, RefData>;
  separator?: string;
  code: string | string[] | undefined;
  field?: string;
};

const RefText: FunctionComponent<RefTextProps> = observer(props => {
  const { map, code, separator = ", ", field = "text" } = props;
  if (!code) {
    return null;
  }
  if (!Array.isArray(code)) {
    const refData = map.get(code);
    return refData ? refData[field] : null;
  }

  return code
    .map(x => map.get(x))
    .filter(refData => !!refData)
    .map(x => x![field])
    .join(separator);
});

export const refTextFor =
  (accessor: (x: IRootStore) => RefDataAccessor) =>
  (props: Omit<RefTextProps, "map"> & Pick<ITextProps, "styles">) => (
    <DataFetcher<
      ObservableMap<string, ConvertToRefData<RefDataDto<string>, string>>
    >
      fetch={x => accessor(x).load()}
    >
      {map =>
        props.styles ? (
          <Text styles={props.styles}>
            <RefText {...props} map={map} />
          </Text>
        ) : (
          <RefText {...props} map={map} />
        )
      }
    </DataFetcher>
  );
export const BillingTransactionStatusText = refTextFor(
  x => x.billing.ref.accScheduleTransactionStatuses
);

export const ClaimStatusText = refTextFor(x => x.acc.ref.claimStatuses);
export const ScheduleProcessingStatusesText = refTextFor(
  x => x.acc.ref.scheduleProcessingStatuses
);
export const ScheduleItemPaymentStatusesText = refTextFor(
  x => x.acc.ref.scheduleItemPaymentStatuses
);
export const TitleText = refTextFor(x => x.core.ref.titles);
export const SexText = refTextFor(x => x.practice.ref.sexes);
export const GenderText = refTextFor(x => x.practice.ref.genders);
export const EthnicityText = refTextFor(x => x.practice.ref.ethnicities);
export const ContactCategoryText = refTextFor(
  x => x.practice.ref.contactCategories
);
export const ContactTypeText = refTextFor(x => x.practice.ref.contactTypes);
export const ContactStatusText = refTextFor(
  x => x.practice.ref.contactStatuses
);

export const InvoiceStatusText = refTextFor(
  x => x.practice.ref.invoiceStatuses
);
export const ItemTypeText = refTextFor(x => x.billing.ref.itemTypes);

export const PaymentMethodText = refTextFor(x => x.billing.ref.paymentMethods);

export const TransactionCancelReasonText = refTextFor(
  x => x.billing.ref.transactionCancelReason
);

export const CommunicationTypeText = refTextFor(
  x => x.core.ref.contactCommTypes
);
export const CorrespondenceTypeText = refTextFor(
  x => x.correspondence.ref.correspondenceTypes
);
export const MedicalCertaintiesText = refTextFor(
  x => x.clinical.ref.medicalCertainties
);
export const CorrespondenceDirectionText = refTextFor(
  x => x.correspondence.ref.correspondenceDirections
);
export const UserTaskStatusText = refTextFor(x => x.inbox.ref.userTaskStatus);
export const InstructionsText = refTextFor(x => x.inbox.ref.instructions);
export const PriorityTypesText = refTextFor(x => x.inbox.ref.priorityTypes);
export const IntakeTypeText = refTextFor(x => x.clinical.ref.intakeTypes);
export const IntakeUnitText = refTextFor(x => x.clinical.ref.intakeUnits);
export const IntakeFrequencyText = refTextFor(
  x => x.clinical.ref.intakeFrequencies
);
export const CessationAdviceText = refTextFor(
  x => x.clinical.ref.cessationAdvices
);
export const CorrespondenceStatusText = refTextFor(
  x => x.correspondence.ref.correspondenceStatuses
);
export const AddressTypeText = refTextFor(x => x.practice.ref.addressTypes);
export const RelationshipTypeText = refTextFor(
  x => x.practice.ref.relationshipTypes
);
export const RelationshipStatusText = refTextFor(
  x => x.practice.ref.relationshipStatuses
);
export const DVACardColourText = refTextFor(x => x.practice.ref.dvaCardColors);
export const LanguageText = refTextFor(x => x.practice.ref.languages);

export const PatientNoticeTypeText = refTextFor(
  x => x.practice.ref.patientNoticeType
);

export const PatientNotePriorityText = refTextFor(
  x => x.practice.ref.patientNoticePriority
);

export const OccupationText = refTextFor(x => x.practice.ref.occupations);
export const OrgRoleTypeText = refTextFor(
  x => x.practice.ref.organisationRoleType
);
export const NatureOfReactionText = refTextFor(
  x => x.clinical.ref.natureOfReactions
);
export const ReactionSeverityText = refTextFor(
  x => x.clinical.ref.reactionSeverities
);
export const InteractionTypeText = refTextFor(
  x => x.clinical.ref.interactionTypes
);
export const ReactionWarningTypeText = refTextFor(
  x => x.clinical.ref.reactionWarningTypes
);
export const AppointmentStatusText = refTextFor(
  x => x.booking.ref.appointmentStatuses
);
export const ClinicalTaskTypeText = refTextFor(
  x => x.clinical.ref.clinicalTaskTypes
);
export const ClinicalActivityTypeText = refTextFor(
  x => x.clinical.ref.clinicalActivityTypes
);
export const PriorityText = refTextFor(x => x.clinical.ref.priorities);
export const ClinicalActivityDescriptionCodeText = refTextFor(
  x => x.clinical.ref.clinicalActivityDescriptions
);
export const ClinicalTaskPriorityText = refTextFor(
  x => x.clinical.ref.clinicalTaskPriorities
);

export const PatientSummaryText = refTextFor(
  x => x.userExperience.ref.patientSummaries
);

export const OrganisationRoleTypeText = refTextFor(
  x => x.practice.ref.organisationRoleType
);

export const InjuryAreasText = refTextFor(x => x.clinical.ref.injuryAreas);
export const MotionTypeText = refTextFor(x => x.clinical.ref.motionTypes);
export const InjuryAreaGroupsText = refTextFor(
  x => x.clinical.ref.injuryAreaMotionTypeGroups
);

export const AppointmentReminderJobStatusText = refTextFor(
  x => x.booking.ref.appointmentReminderJobStatuses
);
export const SideOfBodyText = refTextFor(x => x.clinical.ref.sidesOfBody);
export const DischargeStatusText = refTextFor(
  x => x.clinical.ref.dischargeStatus
);
export const ClaimReviewStatusText = refTextFor(
  x => x.acc.ref.claimReviewStatuses
);

export const ProviderContractTypesText = refTextFor(
  x => x.acc.ref.providerContractTypes
);
